import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { withRouter, useHistory } from "react-router-dom";

const styles = (theme) => ({
  smallButton: {
    padding: 6,
  },
  largeButton: {
    padding: 24,
  },
  largeIcon: {
    fontSize: "3em",
  },
  input: {
    display: "none",
  },
});
export default function ButtonAppBar() {
  const history = useHistory();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{ padding: "10px 0", backgroundColor: "#c33" }}
      >
        <Toolbar>
          <IconButton
            onClick={() => history.push("/")}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <CancelPresentationIcon fontSize="large" />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            style={{ textAlign: "center", marginRight: "16px" }}
          >
            View Rewards
          </Typography>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            style={{ marginRight: "-16px" }}
          >
            <ExitToAppIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
