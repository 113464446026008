import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button, h5, p, h2, div } from "react-bootstrap";
import { AddressMap } from "../components/AddressMap";
import { MenuNavigation } from '../components/MenuNavigation';
import {Footer} from '../components/Footer';
import BlankSpace from "../components/BlankSpace";
import PhotoGalleryList from "../components/PhotoGalleryList";
import React, { useEffect } from 'react'

export const PhotoGallery = () => {
    
useEffect(() => {
document.title = "Photo Gallery"
}, [])    
      
  return (

    <div className="PhotoGallery">
      <MenuNavigation/>
      <div className="HeaderImg">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12}>
              <h2 className="center gold">Photo Gallery</h2>
            </Col>
            <Col xs={10} className="center">
              <h6 className="white">

              </h6>
            </Col>

           <col className="whitespace"></col> 
          </Row>
        </Container>
      </div>
      <BlankSpace/>
      <Container>
        <Row>
            <Col xs={8} className="center">
              <h2 className="">Traditional Chicago Style Food at Dixon, Illinois</h2>
              <h6 className="title">
                {" "}
                We have been going strong since then as we're dedicated to our
                community. We serve traditional Chicago Style dishes made using
                the freshest ingredients at our restaurant. Visit us today!
              </h6>
            </Col>        
        </Row>  
        <Row>  
          <Col>
      <PhotoGalleryList/> 
          </Col>
        </Row>
      </Container>
      <BlankSpace/>
      <Footer/>
    </div>

  );
};
