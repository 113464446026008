import "../../../App.css";
import { Container, Row, Col, Button, h6, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
// import center from '../../../assets/css/custom.css';
import { MenuNavigation } from "../components/MenuNavigation";
import { Footer } from "../components/Footer";
import aboutimg1 from "../../../assets/images/about-img-01.jpg";
import aboutimg2 from "../../../assets/images/about-img-02.jpg";
import aboutimg3 from "../../../assets/images/about-img-03.jpg";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";

export const About = () => {
  useEffect(() => {
    document.title = "About Us";
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="About">
      <MenuNavigation />
      <div className="HeaderImg about-info">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={8}>
              <h6 className="white whitespace">
                Sow Belly's is a locally and family owned business that was
                established on July 1st, 2011.
              </h6>
              <br />
              <h1 className="gold">Traditional Chicago Style Food at</h1>
              <h1 className="gold">Our Locally Owned Restaurant</h1>
            </Col>
            <Col xs={8} className="">
              <h6 className="white">
                {" "}
                We have been going strong since then as we're dedicated to our
                community. We serve traditional Chicago Style dishes made using
                the freshest ingredients at our restaurant. Visit us today!
              </h6>
              <br />
              <Button
                className="btn-red margin-top-10 margin-bottom-10"
                href="/menu"
              >
                Order Now{" "}
              </Button>
            </Col>

            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <div className="col-lg-4">
            <div class="about-info-left">
              <h6>Highlights from the Business</h6>
              <ul className="paddingSpecial">
                <li>Chicago Style food</li>
                <li>Sandwich Shop</li>
                <li>Pizza place</li>
                <li>Outdoor seating</li>
                <li>Pickup and Delivery</li>
                <li>Catering Services</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="about-info-right">
              <h1>Providing a Great Dining Experience to All</h1>
              <h6>
                We're a family-friendly carryout restaurant. A half a block from
                the riverfront with an abundance of seating with beautiful
                views.
              </h6>
              <h6>
                You can hire us to cater your event. From pickup to full-service
                catering.
              </h6>
              <h6>
                We constantly update our menu and ensure that you get great food
                and are satisfied with our service every time you visit us! Like
                us and follow on facebook for daily specials and limited items.
              </h6>
              <br />
              <h4>Chicago Style Food</h4>
              <h6>
                Make your way to Sow Belly's for some Chicago style food. From
                our Homemade Italian Beefs to our Famous Gyros, we carry all
                types of Sandwiches, Appetizers and Pizzas. Visit our Restaurant
                Today!
              </h6>
              <h4>Catering Services</h4>
              <h6>
                Whether you're a Party of 10-300 Sow Belly's can cater it all.
                We'll start your event with a variety of appetizers and side
                dishes. Then we'll set up delicious Home made Pulled Pork,
                Italian Beef or our Hotdog Bar. Either you can pick-up the food
                from our location or we can deliver and set it up for you.
                Everything is made from scratch. Call us for more details.
              </h6>
            </div>
            <div className="inner-about-gallery margin-top-30 ">
              <div className="col-lg-12">
                <div className="col-lg-3 col-md-3 float-left">
                  <img src={aboutimg1} />
                </div>
                <div className="col-lg-3 col-md-3 float-left">
                  <img src={aboutimg2} />
                </div>
                <div className="col-lg-3 col-md-3 float-left">
                  <img src={aboutimg3} />
                </div>
              </div>
            </div>
          </div>

          <col className="whitespace"></col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default withRouter(About);
