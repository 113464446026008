import "../../../App.css";
import { Row, Col, h5, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../../assets/images/logo.png";
import siLogo from "../../../assets/images/servingintel-logo-bw.svg";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { withRouter, useHistory } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FaHeart } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;

export const EloyaltyResetPW = () => {
  useEffect(() => {
    document.title = "Reset Password";
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const paperStyle = {
    padding: 20,
    height: "auto",
    width: 320,
    margin: "20px auto",
  };

  const [password, setPassword] = useState();
  const [cpassword, setCPassword] = useState();
  const [verification, setVerification] = useState();
  const history = useHistory();
  function Copyright(props: any) {
    return (
      <Typography variant="body2" color="white" align="center" {...props}>
        Made with <FaHeart style={{ color: "red" }} /> by <br />
        <Link color="inherit" href="https://servingintel.com">
          <img
            className="Logo"
            style={{ padding: "10px 0", width: "150px" }}
            src={siLogo}
            alt="ServingIntel Logo"
          />
        </Link>
      </Typography>
    );
  }
  const theme = createTheme();

  const submit = () => {
    if (password != cpassword) {
      Swal.fire({
        title: "Password Confirmation",
        text: "Password did not match.",
        icon: "info",
        confirmButtonText: "OK",
      });
      return false;
    }

    if (!password || !cpassword || !verification) {
      Swal.fire({
        title: "Invalid Input",
        text: "Fields marked with * are required",
        icon: "Info",
        confirmButtonText: "OK",
      });
      return false;
    }

    const url =
      "https://ecommv2.servingintel.com/customer/eloyalty-reset-password/" +
      serverId +
      "/" +
      siteId +
      "/" +
      storeId;
    axios
      .post(url, {
        verification: verification,
        password: password,
      })
      .then((response) => {
        if (response.data) {
          Swal.fire({
            title: "Success!",
            text: "Your password has been changed.",
            icon: "success",
            confirmButtonText: "OK",
          });
          history.push("/login");
        } else {
          Swal.fire({
            title: "Failed!",
            text: "Invalid Verification Code.",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="customBg">
      <ThemeProvider theme={theme}>
        <Container
          component="main"
          className="eLoyaltyBg"
          maxWidth="md"
          style={{ marginTop: "1rem" }}
        >
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "5px 20px",
              borderRadius: "5px",
            }}
          >
            <Typography
              component="h4"
              className="white"
              variant="h4"
              style={{ marginTop: "30px", fontWeight: "550" }}
            >
              Welcome to
            </Typography>
            <img
              className="Logo"
              style={{
                padding: "10px 0",
                width: "400px",
                marginBottom: "1rem",
              }}
              src={Logo}
              alt="AJ's Keto Factory"
            />
            <Paper
              className="formBG"
              style={{
                boxShadow: "none",
                marginTop: "0",
                marginBottom: "1rem",
                background: "beige",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                variant="outlined"
              >
                <Typography
                  variant="contained"
                  style={{ margin: "20px auto 0px auto" }}
                >
                  An email has been sent to your email address. The email has a
                  verification code, please paste the verification code in the
                  field below to prove that you are the owner of this account.
                </Typography>
              </Box>
              <Box
                component="form"
                noValidate
                style={{
                  background: "beige",
                  width: "100%",
                  paddingBottom: "1rem",
                }}
              >
                <TextField
                  onChange={(e) => setVerification(e.target.value)}
                  margin="dense"
                  required
                  fullWidth
                  id="verificationcode"
                  label="Verification Code"
                  name="verificationcode"
                />

                <TextField
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  margin="dense"
                  required
                  fullWidth
                  id="password"
                  type="password"
                  label="New Password"
                  name="Password"
                />
                <TextField
                  onChange={(e) => setCPassword(e.target.value)}
                  value={cpassword}
                  margin="dense"
                  required
                  fullWidth
                  id="password2"
                  type="password"
                  label="Confirm New Password"
                  name="confirm password"
                />
              </Box>
            </Paper>
            <Button
              onClick={() => submit()}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, fontWeight: "550" }}
            >
              Submit
            </Button>

            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default withRouter(EloyaltyResetPW);
