import React, { useEffect, useState, Fragment } from "react";
import {
  Autocomplete,
  GoogleMap,
  useJsApiLoader,
  Circle,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import SweetAlert2 from "react-sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedServiceOption,
  setOrderProcessingDate,
  setSelectedAddress,
} from "../../redux/publicReducer/actions";

const storeName = process.env.REACT_APP_RESTAURANT_NAME;
const storeAddress = process.env.REACT_APP_RESTAURANT_ADDRESS;
const deliveryCharge = process.env.REACT_APP_DELIVERY_CHARGE;

const mapContainerStyle = {
  height: "75vh",
  width: "97.75vw",
};

export const DeliveryMap = (props) => {
  //---Map---//
  const [autoComplete, setAautoComplete] = useState();
  const [delAddress, setDelAddress] = useState("");
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const { orderProcessingDate, orderProcessingType, selectedAddress } =
    useSelector((state) => state.public);

  const containerStyle = {
    width: "500px",
    height: "500px",
  };

  const center = {
    lat: 37.717798,
    lng: -89.206162,
  };
  const centerInfo = {
    lat: 37.717936,
    lng: -89.20617,
  };

  const places = [
    {
      id: 1,
      name: "Sowbelly's Sandwich Shop",
      latitude: "37.717798",
      longitude: "-89.206162",
      circle: {
        radius: 3000,
        options: {
          strokeColor: "#ff0000",
        },
      },
    },
  ];

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 8045,
    zIndex: 1,
  };

  const onLoad = (autocomplete) => {
    console.log("autocomplete: ", autocomplete);
    setAautoComplete(autocomplete);
    if (!selectedAddress) {
      dispatch(
        setSelectedAddress({
          address: "",
          city: "",
          email: "",
          first_name: "",
          last_name: "",
          phone: "",
          zipCode: "",
        })
      );
    }
  };

  const area = [
    { lat: 37.789144, lng: -89.205189 },
    { lat: 37.712908, lng: -89.115877 },
    { lat: 37.644237, lng: -89.209509 },
    { lat: 37.713159, lng: -89.297946 },
  ];

  const polygon = new window.google.maps.Polygon({
    path: area,
    geodesic: true,
    strokeColor: "#FF0000",
    strokeOpacity: 0,
    strokeWeight: 0,
    fillColor: "#FFd000",
    fillOpacity: 0,
  });

  const onPlaceChanged = () => {
    console.log("autoComplete: ", autoComplete.getPlace());

    if (
      !window.google.maps.geometry.poly.containsLocation(
        autoComplete.getPlace().geometry.location,
        polygon
      )
    ) {
      setSwalProps({
        show: true,
        title: "Notification",
        text: "Sorry your location is outside the delivery 5 miles radius!",
        icon: "warning",
      });
    } else {
      try {
        selectedAddress.address = autoComplete.getPlace().formatted_address;
        selectedAddress.city =
          autoComplete.getPlace().address_components[2].long_name;
        selectedAddress.zipCode =
          autoComplete.getPlace().address_components[7].long_name;
        dispatch(setSelectedAddress(selectedAddress));
        setDelAddress(autoComplete.getPlace().formatted_address);
        setSwalProps({
          show: true,
          title: "Notification",
          text: "Thank you! Your address is within the delivery 5 miles radius.",
          icon: "success",
        });
      } catch (err) {
        setSwalProps({
          show: true,
          title: "Notification - Invalid Address",
          text: "Please specify Street Address.",
          icon: "warning",
        });
        props.setServiceOptionToPickup();
        setDelAddress("");
      }
    }

    // } else {
    //   console.log("Autocomplete is not loaded yet!");
    // }
  };

  const onLoadCircle = (circle) => {
    console.log("Circle onLoad circle: ", circle);
  };

  const onUnmountCircle = (circle) => {
    console.log("Circle onUnmount circle: ", circle);
    if (delAddress === "") {
      props.setServiceOptionToPickup();
    }
  };

  const onLoadMarker = (marker) => {
    console.log("marker: ", marker);
  };

  const onLoadInfo = (info) => {
    console.log("info: ", info);
  };

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCVgArv2OMsGKqFcnSDvw38PUkWilzdiQo",
    libraries: ["places", "geometry", "drawing"],
    // ...otherOptions
  });

  useEffect(() => {}, []);
  //---End Map---//

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          id="searchbox-example"
          mapContainerStyle={mapContainerStyle}
          zoom={12}
          center={center}
        >
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <input
              type="text"
              placeholder="Enter your Delivery Address here."
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `330px`,
                height: `42px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-170px",
                marginTop: "11px"
              }}
            />
          </Autocomplete>
          <Marker position={center} options={{ zIndex: 10 }}>
            <InfoWindow position={centerInfo}>
              <p style={{ "text-align": "center" }}>
                <strong>{storeName}</strong> {storeAddress} <br />
                Delivery within the geofence with{" "}
                <strong>${deliveryCharge} charge</strong>
              </p>
            </InfoWindow>
          </Marker>

          <Circle
            // optional
            onLoad={onLoadCircle}
            // optional
            onUnmount={onUnmountCircle}
            // required
            center={center}
            // required
            options={options}
          />
        </GoogleMap>
      ) : null}
      <SweetAlert2 {...swalProps} />
    </>
  );
};
