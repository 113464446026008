import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import StoreIcon from "@mui/icons-material/Store";
import RedeemIcon from "@mui/icons-material/Redeem";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import { useHistory, withRouter } from "react-router-dom";
export default function FixedBottomNavigation() {
  const [value, setValue] = React.useState("");
  const history = useHistory();
  const handleChange = (event, newValue) => {
    history.push(`/${newValue}`);
    setValue(newValue);
  };

  return (
    <Box style={{ width: "100%", position: "fixed", bottom: "0" }}>
      <BottomNavigation
        value={value}
        onChange={handleChange}
        showLabels
        style={{ height: "70px" }}
      >
        <BottomNavigationAction label="Home" value="" icon={<StoreIcon />} />
        <BottomNavigationAction
          label="Rewards"
          value="rewards"
          icon={<RedeemIcon />}
        />
        <BottomNavigationAction
          label="Contact"
          value="contact"
          icon={<PermContactCalendarIcon />}
        />
      </BottomNavigation>
    </Box>
  );
}
