import "../../../App.css";
import { Row, Col, h5, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../../assets/images/logo.png";
//import siLogo from "../../../assets/images/si-logo.png";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FaHeart } from "react-icons/fa";
import AppBar from "./components/AppBar";
import FixedBottomNavigation from "./components/FixedBottomNavigation";
import RewardsTabs from "./components/RewardsTabs";
export const Rewards = () => {
  useEffect(() => {
    document.title = "Rewards";
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const btnstyle = { margin: "4px 0" };
  return [<AppBar />, <RewardsTabs />, <FixedBottomNavigation />];
};

export default withRouter(Rewards);
