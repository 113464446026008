import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function BlankSpace() {
  return (
    <Box sx={{ height: '3%', maxHeight: 12 }}>
      <Typography gutterBottom>
      </Typography>
    </Box>
  );
}